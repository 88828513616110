<template>
  <v-app>
    <router-view :key="$route.fullPath"></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App'
};
</script>
<style>
body {
  overflow: hidden;
}
.v-data-table-header th {
  white-space: nowrap;
}
.v-text-field__details {
  margin-bottom: 0 !important;
  padding: 0 !important;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
@font-face {
  font-family: 'RussianRail';
  font-style: normal;
  src: url('~@/components/assets/fonts/fsRail/FSRAIL55.otf') format('truetype');
}
@font-face {
  font-family: 'FsRail';
  font-style: normal;
  src: url('~@/components/assets/fonts/russianRail/RussianRail_G_Regular.otf') format('truetype');
}
@font-face {
  font-family: 'FsRailBold';
  font-style: normal;
  src: url('~@/components/assets/fonts/fsRail/FSRAIL75.otf') format('trueType');
}
</style>
